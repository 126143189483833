<template>
  <a-modal
    :visible="visible"
    @ok="createOrUpdateWorkspace"
    @cancel="cancel"
    okText="确认"
    cancelText="取消"
  >
    <a-form :form="form">
      <a-form-item label="名称">
        <a-input :maxLength="20" v-decorator="['name', ValidateRules.name]" />
      </a-form-item>
      <a-form-item>
        <a-checkbox
          v-model="workspaceParam.default"
          :disabled="editData && editData.default"
          >默认工作空间</a-checkbox
        >
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
//
import {
  newWSByWS,
  setDefaultWorkspace,
  putWorkspace,
} from "@/api/data/workspace.js";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      workspaceParam: {
        name: "",
        default: false,
      },
      ValidateRules: {
        name: { rules: [{ required: true, message: "请输入工作空间名称" }] },
      },
    };
  },
  props: ["visible", "editData"],
  watch: {
    visible() {
      if (this.visible && this.editData) {
        this.workspaceParam = { ...this.editData };
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: this.workspaceParam.name,
          });
        });
      }
    },
  },
  methods: {
    setDefault() {
      this.workspaceParam.default = !this.workspaceParam.default;
    },
    createOrUpdateWorkspace() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // update
          if (this.editData) {
            putWorkspace(this.editData.name, {
              workspace: {
                name: values.name,
              },
            }).then((res) => {
              if (res.status == 200) {
                if (!this.editData.default && this.workspaceParam.default) {
                  setDefaultWorkspace(values.name).then((res) => {
                    if (res.status == 200) {
                      this.$message.success("更新工作空间成功");
                      this.success();
                    }
                  });
                } else {
                  this.$message.success("更新工作空间成功");
                  this.success();
                }
              }
            });
          } else {
            // add
            newWSByWS(
              {
                workspace: {
                  name: values.name,
                },
              },
              this.workspaceParam.default
            ).then((res) => {
              if (res.status == 201) {
                this.$message.success("创建工作空间成功");
                this.success();
              }
            });
          }
        }
      });
    },
    cancel() {
      this.$emit("update:visible", false);
      this.clean();
    },
    success() {
      this.$emit("update:visible", false);
      this.$emit("refreshWS");
      this.clean();
    },
    clean() {
      this.workspaceParam = {
        name: "",
        default: false,
      };
      this.form.setFieldsValue({
        name: "",
      });
    },
  },
  mounted() {},
};
</script>
