<template>
  <div class="workspace">
    <div class="title"></div>
    <div class="crumb"></div>
    <div class="content">
      <div class="actions">
        <div class="search">
          <a-input-search
            class="search-input"
            placeholder="请输入您要查找的内容"
            v-model="searchName"
            @search="onSearch"
          />
        </div>
        <div class="operation">
          <a-button
            class="add"
            type="primary"
            @click="showWSDialog(`new`, null)"
            >添加新的工作空间</a-button
          >
          <a-button class="delete" type="danger" @click="showDelDialog"
            >删除选定的工作空间</a-button
          >
        </div>
      </div>
      <a-card class="table">
        <a-table
          :columns="columns"
          :data-source="showData"
          :row-selection="rowSelection"
        >
          <span slot="name" slot-scope="text, record">
            <a @click="showWSDialog(`edit`, record)">{{ text }}</a>
          </span>
          <span slot="default" slot-scope="text, record">
            <a-switch :checked="text" @change="setDefault(record)" />
          </span>
        </a-table>
      </a-card>
    </div>
    <workspace-info
      :visible.sync="visible"
      :editData="editData"
      @refreshWS="refreshWS"
    ></workspace-info>
    <a-modal
      v-model="delVisible"
      title="提示"
      ok-text="确认"
      cancel-text="取消"
      @ok="deleteSelectedWS"
      @cancel="hideDelDialog"
    >
      <p>删除这些工作区后, 将同时删除这些工作区下所有的数据存储以及图层, 确认操作吗？</p>
    </a-modal>
  </div>
</template>

<script>
import WorkspaceInfo from "./WorkspaceInfo";
import {
  getWorkspaces,
  delWorkspace,
  setDefaultWorkspace,
} from "@/api/data/workspace.js";

const columns = [
  {
    title: "工作空间名称",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "是否默认",
    dataIndex: "default",
    key: "default",
    scopedSlots: { customRender: "default" },
  },
];

export default {
  data() {
    return {
      searchName: null,
      columns,
      data: [],
      showData: [],
      selectedRows: [],
      visible: false,
      delVisible: false,
      editData: null,
    };
  },
  components: {
    WorkspaceInfo,
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  methods: {
    onSearch() {
      if (this.searchName && this.searchName !== "") {
        this.showData = this.data.filter((p) => p.name.indexOf(this.searchName) !== -1);
      } else {
        this.refreshWS();
      }
    },
    setDefault(workspace) {
      if (!workspace.default) {
        setDefaultWorkspace(workspace.name).then((res) => {
          console.log(res)
          this.refreshWS();
        });
      }
    },
    refreshWS() {
      getWorkspaces().then((res) => {
        this.data = res.data.map((item) => {
          item.key = item.id;
          return item;
        });
        this.showData = this.data;
      });
    },
    showWSDialog(type, data) {
      if (type == `edit`) {
        this.editData = data;
      } else {
        this.editData = null;
      }
      this.visible = true;
    },
    showDelDialog() {
      if (this.selectedRows.length == 0) {
        this.$message.info("未选择任何工作空间");
      } else {
        this.delVisible = true;
      }
    },
    hideDelDialog() {
      this.delVisible = false;
    },
    deleteSelectedWS() {
      this.selectedRows.map((item) => {
        delWorkspace(item.name).then((res) => {
          if (res.status == 200) {
            this.$message.success(`已删除工作空间${item.name}`);
          }
          this.refreshWS();
        });
      });
      this.hideDelDialog();
    },

  },
  mounted() {
    this.refreshWS();
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}
.search-input {
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.add,
.delete {
  width: 163px;
  height: 48px;
  border-radius: 4px;
  margin-right: 19px;
}
</style>
<style>
.workspace .search-input .ant-input{
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.workspace .search-input .ant-input-suffix{
  font-size: 18px;
}
</style>